import { TagData } from 'piramis-base-components/src/components/Tags/types'
import BrandTagHelpView from '@/includes/helpViews/BrandTagHelpView.vue'
import ForumTagHelpView from '../includes/helpViews/ForumTagHelpView.vue'

export const ULTIMATE_TAG: Array<TagData> = [
  {
    text: 'Ultimate',
    color: 'rgb(177,69,250)',
  }
]

export const BRAND_TAG: Array<TagData> = [
    {
      text: 'Brand',
      color: 'rgb(177,69,250)',
      hint: BrandTagHelpView,
    }
  ]

export const STARTER_TAG: Array<TagData> = [
    {
      text: 'Starter',
      color: 'rgb(181,192,212)'
    }
]

export const ADVANCED_TAG: Array<TagData> = [
  {
    text: 'Advanced',
    color:'rgb(241,154,64)'
  }
]

export const FORUM_TAG: Array<TagData> = [
  {
    text: 'Forum',
    color: 'rgb(var(--a-success))',
    hint: ForumTagHelpView,
  }
]

export const EXPERIMENTAL_TAG: Array<TagData> = [
  {
    text: 'Experimental',
    color: 'darkseagreen',
  }
]

export const AI_EXPERIMENTAL_TAG: Array<TagData> = [
  {
    text: '🧠 AI Experimental',
    color: 'blue',
    hint: () => import('@/includes/helpViews/AiExperimentalTagHelpView.vue')
  }
]

export const ANONYMOUS_USER_LOGIN = 'GroupAnonymousBot'
export const CHATKEEPER_BOT_LOGIN = 'ChatKeeperBot'

export const DEFAULT_BOARD_TOKEN = 'default'

export const BASE_API_URL = decodeURI(encodeURI("https://api.chatkeeper.info/chat/v1/<TOKEN>"))
