import { CommentsModeEnum } from "./types";
import { BasePunishActionModel, PunishActionSubTypes } from "../PunishActions";

import { Type } from "class-transformer";

export default class CommonFilterSettings {
  enabled_common_filter!: boolean
  comments_mode!: CommentsModeEnum

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  warning_punish_action!: BasePunishActionModel

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  ban_punish_action!: BasePunishActionModel

  notice_before_ban_count!: number

  allowed_count!: number
  allowed_interval!: number
  allowed_interval_round!: number

  ignore_caption!: boolean
  handle_user_bio!: boolean

  take_away_reputation!: number
  take_away_xp!: number

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  first_message_punish_action!: BasePunishActionModel

  new_limit_timeout!: number
  new_limit_msg_count!: number

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  new_limit_punish_action!: BasePunishActionModel

  // removed_edited -- old
  process_edit_separately!: boolean
  remove_edited_timeout!: number
  remove_edited_msg!: number

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  remove_edited_punish_action!: BasePunishActionModel
}
