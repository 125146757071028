import { RootState } from "@/store/state";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

export default {
  _hasPermission: (state: RootState, getters: any) => (permission: SitePermissionEnum) => {
    if (getters.isChatSet) {
      return (!!state.chatState.chat!.permissions.length
          && state.chatState.chat!.permissions.includes(permission))
          || state.chatState.chat!.is_group_owner
    }

    return false
  },
  canStatistics(_: RootState, getters: any) {
    return getters._hasPermission(SitePermissionEnum.Statistics)
  },
  canChangeConfig(_: RootState, getters: any) {
    return getters._hasPermission(SitePermissionEnum.ChangeConfig)
  },
  canAddSiteAdmins(_: RootState, getters: any) {
    return getters._hasPermission(SitePermissionEnum.AddSiteAdmins)
  },
  canPosts(_: RootState, getters: any) {
    return getters._hasPermission(SitePermissionEnum.Posts)
  },
  canAddChatAdmins(_: RootState, getters: any) {
    return getters._hasPermission(SitePermissionEnum.AddChatAdmins)
  },
}
