import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { BasePunishActionModel, PunishActionSubTypes } from "../../PunishActions";

import { Type } from "class-transformer";

export default class WarnCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.WarnCommandHandlerModule;

  @Type(() => WarnCommandHandlerModuleParams)
  params!: WarnCommandHandlerModuleParams;
}

class WarnCommandHandlerModuleParams {
  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  warning_punish_action!: BasePunishActionModel

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  ban_punish_action!: BasePunishActionModel

  notice_before_ban_count!: number

  take_away_reputation!: number
  take_away_xp!: number

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  first_message_punish_action!: BasePunishActionModel

  allowed_interval!: number
  allowed_interval_round!: number

  journal_moderation_warn_warn!: boolean
  journal_moderation_ban_warn!: boolean
}
