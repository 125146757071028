export enum PunishActionTypesEnum {
  Disabled = 'Disabled',
  Notify = 'Notify',
  // UnMute = 'UnMute',
  // UnBan = 'UnBan',
  DeleteMessage = 'DeleteMessage',
  MuteMedia = 'MuteMedia',
  Mute = 'Mute',
  Ban = 'Ban',
  Kick = 'Kick',
  // PreventiveMute = 'PreventiveMute',
  // ReservedBan = 'ReservedBan',

  Incremental = 'Incremental',
  Log = 'Log'
}

