import {
  BanAction, DeleteMessageAction, DisabledAction, IncrementalAction, KickAction,
  MuteAction, MuteMediaAction, NotifyAction, LogAction, PunishActionTypesEnum
} from './'

export const PunishActionSubTypes = [
  { value: BanAction, name: PunishActionTypesEnum.Ban },
  { value: DeleteMessageAction, name: PunishActionTypesEnum.DeleteMessage },
  { value: DisabledAction, name: PunishActionTypesEnum.Disabled },
  { value: IncrementalAction, name: PunishActionTypesEnum.Incremental },
  { value: KickAction, name: PunishActionTypesEnum.Kick },
  { value: MuteAction, name: PunishActionTypesEnum.Mute },
  { value: MuteMediaAction, name: PunishActionTypesEnum.MuteMedia },
  { value: NotifyAction, name: PunishActionTypesEnum.Notify },
  { value: LogAction, name: PunishActionTypesEnum.Log },
]
