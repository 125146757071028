import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { ForcePrefixTypeEnum } from "./types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";
import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";

import { Type } from "class-transformer";

export default class KnowledgeBaseHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.KnowledgeBaseHandlerModule;

  @Type(() => KnowledgeBaseHandlerModuleParams)
  params!: KnowledgeBaseHandlerModuleParams;
}

class KnowledgeBaseHandlerModuleParams {
  @Type(() => KnowledgeBaseArticles)
  articles!: Array<KnowledgeBaseArticles>

  force_prefix_type!: ForcePrefixTypeEnum
  force_prefix!: Array<string>

  footer!: string
  question_patterns!: BlackWhiteListItem<RawChipItem>

  min_percent!: number
  min_words!: number
}

export class KnowledgeBaseArticles {
  @Type(() => MessageEditorWithMediaData)
  text!: Array<MessageEditorWithMediaData>
}
