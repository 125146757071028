import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { BasePunishActionModel, PunishActionSubTypes } from "../../PunishActions";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class VotebanHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SendWakeupMessageModule;

  @Type(() => VotebanHandlerModuleParams)
  params!: VotebanHandlerModuleParams;
}

class VotebanHandlerModuleParams {
  timeout!: number
  limit!: number

  @Type(() => MessageEditorWithMediaData)
  message!: Array<MessageEditorWithMediaData>

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  punish_action!: BasePunishActionModel

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  actor_punish_action!: BasePunishActionModel

  punish_button!: string
  forgive_button!: string

  protection!: {
    count: number
    interval: number
  } | null
}
