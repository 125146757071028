import { MessagePunishAction, PunishActionTypesEnum } from "../..";

import { snakeCase } from "lodash";

export class MuteAction extends MessagePunishAction {
  type = PunishActionTypesEnum.Mute

  delete_message!: boolean
  delete_message_delay!: number
  time!: number

  previewActionTitleKey() {
    if (this.delete_message) {
      return `punish_action_type_${ snakeCase(this.type) }_with_delete`
    }

    return `punish_action_type_${ snakeCase(this.type) }_without_delete`
  }
}

