import { RootState } from '../state';
import { BaseChatInfo } from "@/includes/types/Chat/types";
import achievements from "./achievements/getters";
import permissions from "./permissions/getters";

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { isEqual } from "lodash";

const getters = {
  isChatSet: (state: RootState) => {
    return state.chatState.chat !== null
  },
  chatId: (state: RootState) => {
    if (state.chatState.chat) {
      return state.chatState.chat.chat_id
    }

    return null
  },
  getHaveLicense: (state: RootState) => {
    if (state.chatState.chat) {
      return state.chatState.chat.license_type === 2;
    }

    return false
  },
  getHaveBrand: (state: RootState) => {
    if (state.chatState.chat) {
      return state.chatState.chat.brand !== undefined && state.chatState.chat.brand !== null;
    }

    return false
  },
  isChatLicenseExists: (state: RootState) => {
    if (state.chatState.chat) {
      return state.chatState.chat.license_left > 0 && state.chatState.chat.license_timestamp !== "";
    }

    return false
  },
  isChatAtLeastAdvanced: (state: RootState) => {
    if (state.chatState.chat) {
      return state.chatState.chat.license_type >= 1;
    }

    return false
  },
  isChatForum: (state: RootState) => {
    if (state.chatState.chat) {
      return state.chatState.chat.type === 'forum';
    }

    return false
  },
  hasHiddenChats: (state: RootState) => {
    const chats = state.chatState.chatsInfo?.chats

    if (chats && chats.length > 0) {
      return chats.some((chat) => chat.hidden)
    }

    return false
  },
  hasAtLeastOneAdvanced: (state: RootState) => {
    const chats = state.chatState.chatsInfo?.chats

    if (chats && chats.length > 0) {
      return chats.some((chat) => chat.license_type >= 1)
    }

    return false
  },
  sortedVisibleChatsInfos: (state: RootState) => {
    const chats = state.chatState.chatsInfo?.chats

    if (chats && chats.length > 0) {
      return chats
          .slice()
          .filter((chat) => !chat.hidden)
          .sort((a, b) => b.member_count - a.member_count
              || b.license_type - a.license_type
              || b.license_left - a.license_left
              || Math.abs(b.chat_id) - Math.abs(a.chat_id)
          )
    }

    return []
  },
  chatSelectOptions: (state: RootState, getters: any): Array<SelectOptionData> => {
    return (getters.sortedVisibleChatsInfos as Array<BaseChatInfo>)
        .map(c => ({
          value: c.chat_id,
          label: c.group_title,
          image: {
            src: c.group_photo
          }
        }
    ))
  },
  botNotAdmin: (state: RootState) => {
    return state.chatState.chat?.diagnostic?.bot_not_admin
  },
  isChatConfigChanged: (state: RootState) => {
    if (state.chatState.chat && state.chatState.backupConfig) {
      return !isEqual(state.chatState.chat.common_config, state.chatState.backupConfig.common_config)
    }

    return false
  },
  isRuChat: (state: RootState) => {
    return state.chatState.chat && state.chatState.chat.language_code === "ru";
  },
  ...achievements,
  ...permissions
}

export default getters;
