import { AddAdminType } from "@/includes/types/AddAdmin/Enums";

export type FullAddAdminRequest = {
  chat_id: number
  type: AddAdminType
  username: string
  module?: string
  permissions?: Array<SitePermissionEnum>
}

export enum SitePermissionEnum {
  Statistics = "Statistics",
  AddSiteAdmins = "AddSiteAdmins",
  AddChatAdmins = "AddChatAdmins",
  Posts = "Posts",
  ChangeConfig = "ChangeConfig",
}

export type AddAdminByLinkRequest = Omit<FullAddAdminRequest, 'username'>
export type AddAdminByLinkResponse = {
  token: string
  link: string
}

export type UsersResultResponse = {
  /**
  * deprecated
  */
  users?: Array<string>
  users_map?: Record<number, string>
  user_id: number
}

export type AdminState = {
  token: string
}

export type BindState = {
  binded: boolean
} & Partial<UsersResultResponse>
