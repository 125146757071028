import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import PhotoFilterHandlerModule from "./PhotoFilterHandlerModule";

export default class PhotoFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: PhotoFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "photo",
    color: "rgba(var(--a-success), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/PhotoFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', 'фото', 'медиа' ]

}
