import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { BasePunishActionModel, PunishActionSubTypes } from "../../PunishActions";

import { Type } from "class-transformer";

export default class NsfwHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NsfwHandlerModule;

  @Type(() => NsfwHandlerModuleParams)
  params!: NsfwHandlerModuleParams;
}

class NsfwHandlerModuleParams {
  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  punish_action!: BasePunishActionModel
}

