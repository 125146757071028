import { plainToInstance } from "class-transformer";
import { PunishActionSubTypes, PunishActionTypesEnum } from ".";

export abstract class BasePunishActionModel {
  abstract type: PunishActionTypesEnum

  static fromObject(value: any): BasePunishActionModel {
    // @ts-ignore
    return plainToInstance(PunishActionSubTypes.find(pa => pa.name === value.type)?.value ?? BasePunishActionModel, value)
  }

  async validate() {
    return true
  }
}
