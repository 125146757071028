import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { BasePunishActionModel, PunishActionSubTypes } from "../../PunishActions";

import { Type } from "class-transformer";

export default class MessageBindedChannelHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.MessageBindedChannelHandlerModule;

  @Type(() => MessageBindedChannelHandlerModuleParams)
  params!: MessageBindedChannelHandlerModuleParams;
}

class MessageBindedChannelHandlerModuleParams {
  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  ban_action!: BasePunishActionModel

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  warn_action!: BasePunishActionModel

  notice_before_ban_count!: number
  recheck_interval!: number
  binded_channel_id!: number

  journal!: boolean
}
